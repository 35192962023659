<template>
  <div>
    <div class="row row-sm">
      <div class="col-3">
        <label for=""> Employee <span class="text-danger">*</span> </label>
        <select
          v-model="filter.employee_reference"
          class="form-select"
          required
        >
          <option selected value="">--</option>
          <option
            v-for="(employee, index) in employees"
            :key="index++"
            :value="employee.reference"
            :style="
              employee.blocked || employee.fullName.includes('Bloqué')
                ? { color: 'red' }
                : ''
            "
          >
            {{ employee.fullName }}
          </option>
        </select>
      </div>

      <div class="col">
        <label for="">Du <span class="text-danger">*</span> </label>
        <input v-model="filter.startDate" type="date" class="form-control" />
      </div>
      <div class="col">
        <label for=""> Au <span class="text-danger">*</span> </label>
        <input v-model="filter.endDate" type="date" class="form-control" />
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="toFilter(filter)" class="btn btn0 btn-secondary">
          <i class="bi bi-funnel"></i> Rechercher
        </button>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="print(filter)" class="btn btn-secondary">
          <i class="bi bi-printer"></i> Imprimer
        </button>
      </div>
    </div>
    <br />
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'transactions-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un nouveau transaction
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col"> Employee </th>
          <th scope="col">Date</th>
          <th scope="col">Montant </th>
          <th scope="col">Mode</th>
          <th scope="col">Destination</th>
          <th scope="col">Remarque</th>
          <th scope="col">Créer par</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(transaction, index) in transactions" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ transaction.reference }}
          </td>
          <td>
            <span
              v-if="transaction.employee"
              :class="
                transaction.employee.deleted_at
                  ? 'text-danger text-decoration-line-through'
                  : ''
              "
            >
              {{ transaction.employee.fullName }}
            </span>
          </td>
          <td>{{ transaction.date }}</td>

          <td >
            {{ transaction.amount }}
          </td>
          <td >
            <span>
              {{ transaction.transactionMethod }}
            </span>
          </td>
          
          <td>
            {{ transaction.destination }}
          </td>

          <td>
            {{ transaction.remark }}
          </td>

          <td>
            <span v-if="transaction.user">
              {{ transaction.user.name }}
            </span>
          </td>
          <td class="p-0 d-flex">
            <button
              @click="
                $router.push({
                  name: 'transactions-details',
                  params: { reference: transaction.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>

            <button
              @click="printReceipt(transaction.reference)"
              class="btn"
            >
              <i class="bi bi-printer fs-5"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col">
            <span class="bg-success py-1 px-2 rounded text-white">
              {{ totalAmount | toFixedWithSpace }}
            </span>
          </th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
      filter: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
    };
  },
  computed: {
    ...mapGetters("transaction", {
      transactions: "getTransactions",
      totalAmount: "getTotalAmount",
    }),
    ...mapGetters("employee", {
      employees: "getAll",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  mounted() {
    this.$store.dispatch("transaction/getAll");
    this.$store.dispatch("employee/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("transaction/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("transaction/getAll");
    },

    async sort(value) {
      return await this.$store.commit("transaction/sort", value);
    },
    async toFilter(data) {
      return await this.$store.dispatch("transaction/filter", data);
    },
    async printReceipt(reference) {
      await this.$store.dispatch("transaction/printReceipt", reference);
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
};
</script>
